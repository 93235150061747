import { cva } from 'class-variance-authority';

export const progressBarLinearBackgroundCVA = cva(
  'relative w-full rounded-dt-radius-medium overflow-hidden text-dt-theme-text-text-100',
  {
    variants: {
      device: {
        'tv': 'h-8 min-h-8',
        'web': 'h-4 min-h-4',
      },
      shouldForceDarkTheme: {
        true: 'bg-dt-theme-background-progressbar-progressbar-forced-dark',
        false: undefined,
      },
    },
    compoundVariants: [
      {
        device: 'tv',
        shouldForceDarkTheme: false,
        className: 'bg-dt-theme-tv-surface-progressbar-progressbar-background',
      },
      {
        device: 'web',
        shouldForceDarkTheme: false,
        className: 'bg-dt-theme-background-progressbar-progressbar',
      },
    ],
  }
);

export const progressBarLinearForegroundCVA = cva(
  'absolute top-0 h-[inherit] min-h-[inherit]',
  {
    variants: {
      device: {
        'tv': undefined,
        'web': undefined,
      },
      variant: {
        live: undefined,
        ongoing: undefined,
      },
      shouldForceDarkTheme: {
        true: undefined,
        false: undefined,
      },
    },
    compoundVariants: [
      {
        variant: 'live',
        device: 'web',
        className: 'bg-dt-theme-surface-progressbar-progressbar-live',
      },
      {
        variant: 'live',
        device: 'tv',
        className: 'bg-dt-theme-tv-surface-progressbar-progressbar-live',
      },
      {
        variant: 'ongoing',
        device: 'web',
        shouldForceDarkTheme: false,
        className: 'bg-dt-theme-surface-progressbar-progressbar-on-going',
      },
      {
        variant: 'ongoing',
        device: 'tv',
        shouldForceDarkTheme: false,
        className: 'bg-dt-theme-tv-surface-progressbar-progressbar-on-going',
      },
      {
        variant: 'ongoing',
        shouldForceDarkTheme: true,
        className: 'bg-[--dt-global-palette-white-100]',
      },
    ],
  }
);

export const progressBarLabelCVA = cva(
  'font-dt-font-family-system font-dt-font-weight-regular whitespace-nowrap',
  {
    variants: {
      shouldForceDarkTheme: {
        true: 'text-[--dt-global-palette-white-100]',
        false: 'text-dt-theme-text-text-50',
      },
      variant: {
        live: 'relative before:content-[""] before:rounded-full before:absolute-y-center',
        ongoing: undefined,
      },
      device: {
        tv: 'text-dt-font-size-24 leading-dt-font-line-height-28',
        web: 'text-dt-font-size-12 leading-dt-font-line-height-16',
      },
    },
    compoundVariants: [
      {
        variant: 'ongoing',
        device: 'web',
        className: 'pl-dt-spacing-100',
      },
      {
        variant: 'ongoing',
        device: 'tv',
        className: 'pl-dt-spacing-150',
      },
      {
        variant: 'live',
        device: 'web',
        className:
          'pl-dt-spacing-250 before:size-8 before:left-8 before:bg-dt-theme-surface-progressbar-progressbar-live',
      },
      {
        variant: 'live',
        device: 'tv',
        className:
          'pl-dt-spacing-350 before:size-10 before:left-12 before:bg-dt-theme-tv-surface-progressbar-progressbar-live',
      },
    ],
  }
);
