import classNames from 'classnames';
import type { JSX } from 'react';
import { useDiveContext } from '../../../context';
import { truncate } from '../../../helpers/helpers';
import { useMediaContext } from '../provider';
import type { MediaFooterProps } from './MediaFooter.types';

export const DESCRIPTION_LENGTH_MAX = 250;

/**
 * Renders `Media` related editorial content
 *
 * | Content       | Overflows to `...` when |
 * |---------------|-------------------------|
 * | `title`       | > 2 lines               |
 * | `subtitle`    | > 2 lines               |
 * | `description` | > 250 characters        |
 *
 * @example
 *
 * ```tsx
 * <MediaFooter
 *   title="Avatar: The Way of Water"
 *   subtitle='Return to Pandora.'
 *   description='Learn the story of the Sully family'
 *  />
 * ```
 */
export function MediaFooter({
  title,
  subtitle,
  description,
  actionableElement,
  lineClamp = 2,
}: MediaFooterProps): JSX.Element | null {
  const { isTv } = useDiveContext();
  const { footerPosition = 'default' } = useMediaContext();

  if (!(title || subtitle || description)) {
    return null;
  }

  const lineClampClass =
    lineClamp !== null
      ? `overflow-hidden line-clamp-${lineClamp} text-ellipsis`
      : '';

  return (
    <div
      className={classNames(
        'font-dt-font-family-system font-dt-font-weight-regular flex-1'
      )}
    >
      {(title || subtitle || actionableElement) && (
        <div
          className={classNames(
            'flex flex-1 justify-between space-x-dt-spacing-100',
            {
              'items-start': footerPosition === 'default',
              'items-center dt-breakpoint-sm-start:items-start':
                footerPosition === 'responsive',
              'items-center': footerPosition === 'right',
            }
          )}
        >
          {(title || subtitle) && (
            <div className="flex flex-col [&>p]:mb-dt-spacing-none">
              {title && (
                <p
                  className={classNames(
                    lineClampClass,
                    isTv
                      ? [
                          'text-dt-font-size-30 leading-dt-font-line-height-36',
                          'text-dt-theme-tv-text-media-block-media-block-title ',
                          'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-title-focus ',
                        ]
                      : [
                          'text-dt-font-size-16 leading-dt-font-line-height-20',
                          'text-dt-theme-text-media-block-media-block-title',
                        ],
                    // ! tailwind-base-styles
                    'mt-dt-spacing-none'
                  )}
                >
                  {title}
                </p>
              )}
              {subtitle && (
                <p
                  className={classNames(
                    'mt-dt-spacing-25',
                    lineClampClass,
                    isTv
                      ? [
                          'text-dt-font-size-28 leading-dt-font-line-height-32',
                          'text-dt-theme-tv-text-media-block-media-block-subtitle',
                          'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-subtitle-focus',
                        ]
                      : [
                          'text-dt-font-size-14 leading-dt-font-line-height-18',
                          'text-dt-theme-text-media-block-media-block-subtitle',
                        ]
                  )}
                >
                  {subtitle}
                </p>
              )}
            </div>
          )}
          {actionableElement}
        </div>
      )}
      {description && (
        <p
          className={classNames(
            'mb-dt-spacing-none',
            isTv
              ? [
                  'text-dt-font-size-28 leading-dt-font-line-height-32 mt-dt-spacing-100',
                  'text-dt-theme-tv-text-media-block-media-block-description',
                  'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-description-focus',
                ]
              : [
                  'text-dt-font-size-14 leading-dt-font-line-height-18 mt-dt-spacing-75',
                  'text-dt-theme-text-media-block-media-block-description',
                ]
          )}
        >
          {truncate(description, { length: DESCRIPTION_LENGTH_MAX })}
        </p>
      )}
    </div>
  );
}
