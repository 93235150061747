import classNames from 'classnames';
import type { ElementType, JSX } from 'react';
import { cloneElement } from 'react';
import { useDiveContext } from '../../../context';
import { DiveDevice } from '../../../types/Dive.types';
import { buttonLogoWrapperCVA } from './ButtonLogo.cva';
import type { ButtonLogoProps } from './ButtonLogo.types';

/**
 * A ButtonLogo is a clickable element used to perform an action, wrapping an SVG or bitmap logo.
 *
 * The component can be rendered as non-actionable by setting the `as` prop to `div`.
 *
 * @example
 *
 * ```tsx
 * <ButtonLogo logo={<ChannelCanalBoxOfficeSvg />} />
 * <ButtonLogo as='div' logo={<MediaImage src="img.jpg" aria-label="lorem" />} />
 * <ButtonLogo logo={<img src="img.jpg" aria-label="lorem" />} />
 * ```
 */
export function ButtonLogo<T extends ElementType>({
  as: Wrapper = 'button',
  logo,
  className,
  variant = 'solid',
  'data-testid': dataTestId,
  ...props
}: ButtonLogoProps<T>): JSX.Element {
  const { device } = useDiveContext();

  return (
    <Wrapper
      data-testid={dataTestId}
      className={classNames(
        buttonLogoWrapperCVA({ device, wrapper: Wrapper, variant }),
        className
      )}
      {...props}
    >
      {logo &&
        cloneElement(logo, {
          className: classNames('box-border', {
            'px-dt-spacing-200 py-dt-spacing-150': device === DiveDevice.Web,
            'px-dt-spacing-450 py-dt-spacing-350': device === DiveDevice.Tv,
          }),
        })}
    </Wrapper>
  );
}
