import type { ReactElement } from 'react';
import type {
  DiveIconPosition,
  DiveProps,
  DiveSvgProps,
} from '../../types/Dive.types';

export enum StickerVariant {
  Live = 'live',
  Date = 'date',
  Subscribe = 'subscribe',
  Get = 'get',
  OfferedContent = 'offered-content',
  FreeToAir = 'free-to-air',
  Order = 'order',
  LastDays = 'last-days',
  Duration = 'duration',
}

export type StickerProps = {
  /**
   * The label to be displayed within the Sticker
   */
  label: string;
  /**
   * The variant of the Sticker bearing a specific color scheme
   *
   * @default 'date'
   */
  variant?: `${StickerVariant}`;
  /**
   * Takes an SVG icon as a `ReactElement`, to be displayed alongside the `label`
   */
  icon?: ReactElement<DiveSvgProps>;
  /**
   * The position of the `icon` element relative to the `label`
   *
   * @default 'left'
   */
  iconPosition?: `${DiveIconPosition}`;
} & DiveProps;
